import type { AppConfig } from './config-client.schema';

export const config: AppConfig = {
	// appDomain: 'https://retailink-01.web.app',
	appDomain: 'https://app.retailink.co',
	countryCode: '+31',
	localeGroup: 'retailink',
	availableLocales: ['en', 'fr', 'es'],
	defaultLocale: 'en',

	pwaItems: {
		name: 'RetaiLink',
		short_name: 'RetaiLink',
		description: '',
		theme_color: '#00a486',
	},

	allowedNumbers: [],

	maxSMSLength: 160,

	firebaseConfig: {
		apiKey: 'AIzaSyDQnpbby1Sd6m4j_tP-EskzRJSKABoWh0g',
		authDomain: 'retailink-01.firebaseapp.com',
		projectId: 'retailink-01',
		storageBucket: 'retailink-01.appspot.com',
		messagingSenderId: '604715048489',
		appId: '1:604715048489:web:c153c28ec98fe11a970638',
		measurementId: 'G-S424N8MJ26',
		vapidKey: '',
		recaptchaV3publicKey: '6LdHZgIqAAAAAF1PNNYEkJUjWu4JKWwouoEl9KnB',
	},

	apiDev: 'http://127.0.0.1:5001/retailink-01/us-central1/',
	apiProd: 'https://us-central1-retailink-01.cloudfunctions.net/',

	appConfig: {
		hasPrivacyNotice: true,
		hasTandC: true,
		experimental: true,
		shopActionsConfig: {
			userTooLongInactive: 7 * 24 * 60 * 60 * 1000, // one week
		},
		wantsMiddleName: true,
		allowExcelImport: true,
		systemDown: false,
	},

	tenants: [
		{
			id: '1',
			short_title: 'Admin',
		},
		{
			id: '2',
			short_title: 'Guilgal',
		},
		{
			id: '3',
			short_title: 'Crédit Access',
		},
		{
			id: '4',
			short_title: 'Zanaco',
		},
		{
			id: '5',
			short_title: 'EBO',
		},
		{
			id: '6',
			short_title: 'NBS',
		},
		{
			id: '7',
			short_title: 'UFC',
		},
		{
			id: '8',
			short_title: 'ESP',
		},
	],
};

export enum ShopType {
	AGRIBUSINESS = 'AGRIBUSINESS',
	RETAIL = 'RETAIL',
	WHOLESALE = 'WHOLESALE',
	TENANT = 'TENANT',
	OUTLET = 'OUTLET',
}
